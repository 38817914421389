import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "toast",
      "style": {
        "position": "relative"
      }
    }}>{`Toast`}</h1>
    <p>{`Use toast to show information that requires minimal user interaction. Show these event-driven messages by overlaying the message at the bottom left of the screen, as if it was emerging from the navigation sidebar. Toasts can close automatically after 5 seconds, or they may require manual close action.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Try to keep the text of message concise: it can be up to two lines in length.`}</li>
      <li parentName="ul">{`Use dismiss icon to close flags.`}</li>
      <li parentName="ul">{`The flag must close after an action is taken, or after 5 seconds. If the flag receives hover status, it should be kept open.`}</li>
      <li parentName="ul">{`Use links that allow users to respond to the flag's content.`}</li>
      <li parentName="ul">{`Informative icons can be used to make the message clear.`}</li>
    </ul>
    <p><strong parentName="p">{`This component hasn't yet been implemented as a react component.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      